<template>
  <div>
    <div class="header">
      <h4>子用户列表</h4>
      <div style="display: flex; align-item: center">
        <el-button size="small" type="text"> 子用户使用文档 </el-button>
        <el-button
          size="small"
          @click="dialogFormVisible = true"
          type="primary"
          v-access
          data-ctrl="subUser.platformAddSub"
        >
          创建子账号
        </el-button>
        <el-button
          size="small"
          @click="agentVisible = true"
          type="primary"
          v-access
          data-ctrl="subUser.agentAddSub"
        >
          创建子账号
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="EnterpriseVisible = true"
          v-access
          data-ctrl="subUser.enterpriseAddSub"
        >
          创建子账号
        </el-button>
      </div>
    </div>
    <ListFilter :total="total" @close="putAway">
      <div>
        关键字：
        <el-input
          placeholder="请输入姓名、手机号、身份证号码"
          v-model="formInline.keywords"
          @input="loadData()"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        >
        </el-input>
        角色：
        <el-select
          v-model="formInline.roles"
          placeholder="请选择角色"
          style="width: 200px"
          clearable
          filterable
          size="mini"
          @change="rolesChange"
        >
          <el-option
            v-for="(item, index) of roleOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </ListFilter>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="序号" type="index" width="50"> </el-table-column>
      <el-table-column label="姓名&账号">
        <template slot-scope="scope">
          {{ scope.row.name || scope.row.code }}
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="所属企业">
        <template slot-scope="scope">
          {{ scope.row.enterprise_name }}
        </template>
      </el-table-column>
      <el-table-column label="角色">
        <template slot-scope="scope">
          {{ scope.row.roles | roleVal }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          {{ scope.row.create_time | dateVal }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passwordFormVisible"
      width="500px"
    >
      <el-form
        ref="passwordform"
        :model="passwordform"
        :rules="passwordRuleForm"
      >
        <el-form-item prop="password">
          <el-input
            v-model="passwordform.password"
            type="password"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closePassword('passwordform')">取 消</el-button>
        <el-button type="primary" @click="passwordSubmit('passwordform')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除提示 -->
    <el-dialog title="删除提示" :visible.sync="dialogVisible" width="30%">
      <span>{{ tips }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 平台创建一级代理 -->
    <AddSubuser
      :dialogFormVisible.sync="dialogFormVisible"
      :role="'platform'"
      @close="loadData"
    ></AddSubuser>
    <!-- 一级代理创建二级代理 -->
    <AddSubuser
      :dialogFormVisible.sync="agentVisible"
      :role="'agent_root'"
      @close="loadData"
    ></AddSubuser>
    <!-- 代理创建代理用户 -->
    <AddSubuser
      :dialogFormVisible.sync="EnterpriseVisible"
      :role="'enterprise_agent'"
      @close="loadData"
    ></AddSubuser>
  </div>
</template>

<script>
import { phoneReg } from '../../../lib/global';
import { api } from '../../../api';
export const userDescribeSubuser = api()('user.describe.subuser.json');
export const enterpriseList = api()('enterprise.list.json');
export const userAddSubuser = api('/subuser')('user.add.subuser.json');
export const userdelSubuser = api('/subuser')('user.del.subuser.json');
export const userEditSubuserRole = api('/subuser')(
  'user.edite.subuser.role.json'
);
export const userEditSubuserPassword = api('/subuser')(
  'user.edite.subuser.password.json'
);
export const userEditSubuserEnable = api('/subuser')(
  'user.edite.subuser.enable.json'
);
// 查询用户结算中心权限
export const financialSubAccountList = api('/financialSubAccount')(
  'financialSubAccount.list.json'
);

export default {
  components: {
    ListFilter: () => import('/src/components/filter'),
    AddSubuser: () => import('./addSubuser.vue')
  },
  data() {
    return {
      formInline: {
        keywords: '',
        roles: ''
      },
      total: 0,
      list: [],
      dialogFormVisible: false,
      form: {
        name: '',
        phone: '',
        username: '',
        password: '',
        roleName: 'agent_root',
        enterpriseCode: ''
      },
      ruleForm: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        roleName: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        enterpriseCode: [
          { required: true, message: '请选择企业', trigger: 'change' }
        ]
      },
      namespace: 'namespace',
      passwordFormVisible: false,
      passwordform: {
        password: ''
      },
      passwordRuleForm: {
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
      },
      platformList: [],
      roleOptions: [
        {
          label: '放薪管家财务人员',
          value: 'enterprise_finance'
        },
        {
          label: '放薪管家车队长',
          value: 'enterprise_carLeader'
        },
        {
          label: '一级代理',
          value: 'agent_root'
        },
        {
          label: '二级代理',
          value: 'enterprise_agent'
        },
        {
          label: '代理商成员',
          value: 'enterprise_agent_user'
        },
        {
          label: '陕西平台',
          value: 'platform_sx'
        },
        {
          label: '财务人员',
          value: 'platform_finance'
        }
      ],
      dialogVisible: false,
      tips: '',
      row: {},
      eList: [],
      agentVisible: false,
      EnterpriseVisible: false
    };
  },
  async created() {
    this.loadData();
    this.platformList = this.$route.params.$preload.platformList;
    this.getEList();
  },
  methods: {
    async getEList() {
      const res = await enterpriseList({ pageNumber: 1, pageSize: 10000 });
      this.eList = res.list;
    },
    enterpriseChange() {
      this.namespace = this.eList.find(
        it => it.code === this.form.enterpriseCode
      ).namespace;
    },
    rolesChange() {
      this.pageNumber = 1;
      this.loadData();
    },
    async loadData() {
      const params = {
        pageSize: 1000,
        pageNumber: 1,
        roless: [
          'enterprise_agent_user',
          'agent_root',
          'enterprise_agent',
          'enterprise_finance',
          'enterprise_carLeader',
          'platform_sx'
        ],
        ...this.formInline,
        $ignoreRepeat: true
      };
      await userDescribeSubuser(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    onSubmit(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          if (!phoneReg.test(this.form.phone)) {
            return this.$message.error('请输入正确的手机号码！');
          }
          await userAddSubuser({
            ...this.form,
            group: this.form.enterpriseCode,
            username: this.form.username + '@' + this.namespace
          }).then(() => {
            this.$message.success('创建成功！');
            this.closeDialog(form);
            this.loadData();
          });
        } else {
          return false;
        }
      });
    },
    closeDialog(form) {
      this.namespace = 'namespace';
      this.dialogFormVisible = false;
      this.$refs[form].resetFields();
    },
    passwordChange(row) {
      this.code = row.code;
      this.passwordFormVisible = true;
    },
    passwordSubmit(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          await userEditSubuserPassword({
            code: this.code,
            ...this.passwordform
          }).then(() => {
            this.closePassword(form);
            this.$message.success('密码修改成功！');
          });
        } else {
          return false;
        }
      });
    },
    closePassword(form) {
      this.$refs[form].resetFields();
      this.passwordFormVisible = false;
    },
    async enable(row, type) {
      await userEditSubuserEnable({
        code: row.code,
        delete: type === 0
      });
      this.loadData();
      this.$message.success(`${type === 0 ? '停用' : '启用'}成功`);
    },
    putAway() {
      Object.keys(this.formInline).forEach(it => {
        this.formInline[it] = '';
      });
      this.pageNumber = 1;
      this.loadData();
    },
    async del(row) {
      this.row = row;
      if (row.roles === 'enterprise_agent') {
        this.tips = '请先设置新的代理管理员，再进行删除！';
      }
      if (row.roles === 'enterprise_agent_user') {
        this.tips = '请先更换新的业务人员，再进行删除！';
      }
      if (row.roles === 'enterprise_finance') {
        this.tips = '请先设置新的财务代理人员，再进行删除！';
      }
      if (row.roles === 'enterprise_carLeader') {
        this.tips = '请先设置新的车队长，再进行删除！';
      }
      this.dialogVisible = true;
    },
    async confirm() {
      await userdelSubuser({
        code: this.row.code
      });
      this.$message.success(
        `帐户${this.row.name || this.row.phone}已成功删除！`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}
</style>
